import '../components/wdyr';

import ProgressBar from '@badrap/bar-of-progress';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import { AppProps } from 'next/app';
import Head from 'next/head';
import Router from 'next/router';
import { Provider } from 'next-auth/client';
import { SnackbarProvider } from 'notistack';
import React, { createRef, ReactElement, ReactText, useEffect } from 'react';

import { Snack } from '../components/common';
import { defaultProgressBarOptions } from '../config';
import theme from '../theme';

const progress = new ProgressBar(defaultProgressBarOptions);

Router.events.on('routeChangeStart', progress.start);
Router.events.on('routeChangeComplete', progress.finish);
Router.events.on('routeChangeError', progress.finish);

export default function MyApp({ Component, pageProps }: AppProps): ReactElement {
  const notistackReference = createRef<SnackbarProvider>();

  const onClickDismiss = (key: ReactText) => (): void => notistackReference.current?.closeSnackbar(key);

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');

    if (jssStyles) {
      jssStyles.remove();
    }
  }, []);

  return (
    <>
      <Head>
        <title>S&F Financial App</title>
        <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      </Head>

      <ThemeProvider theme={theme}>
        <Provider session={pageProps.session}>
          <CssBaseline />
          <SnackbarProvider
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            ref={notistackReference}
            maxSnack={3}
            action={(key) => <Snack onClick={onClickDismiss(key)} />}
            dense
          >
            <Component {...pageProps} />
          </SnackbarProvider>
        </Provider>
      </ThemeProvider>
    </>
  );
}
